"use client"

import { usePathname, useSearchParams } from "next/navigation"
import { useEffect } from "react"
import { useAnalytics, useAnonAnalytics } from ".."
import { ClickIdType } from "@store-platform/backend/common"

const useClickIdObserver = (
  track: (id: ClickIdType, value: string) => void,
) => {
  const searchParams = useSearchParams()

  useEffect(() => {
    const fbClickId = searchParams.get("fbclid")
    if (fbClickId) track("fb", fbClickId)

    const googleClickId = searchParams.get("gclid")
    if (googleClickId) track("google", googleClickId)

    const gbraid = searchParams.get("gbraid")
    if (gbraid) track("gbraid", gbraid)

    const wbraid = searchParams.get("wbraid")
    if (wbraid) track("wbraid", wbraid)
  }, [searchParams, track])
}

export function PageViewObserver() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { trackerReady, trackPage, trackClickId } = useAnalytics()

  useEffect(() => {
    if (trackerReady) trackPage(pathname, searchParams)
  }, [pathname, searchParams, trackerReady, trackPage])

  useClickIdObserver(trackClickId)

  return null
}

export function PageViewAnonymousObserver() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { trackerReady, trackPage, trackClickId } = useAnonAnalytics()

  useEffect(() => {
    if (trackerReady) trackPage(pathname, searchParams)
  }, [pathname, searchParams, trackerReady, trackPage])

  useClickIdObserver(trackClickId)

  return null
}
